

.page {
  min-height: calc(100vh - 240px);
}

.page-fullcover {
  @apply 
      fixed
      top-9 left-0 bottom-0 right-0 
      flex justify-center items-center 
      w-screen h-screen
      pointer-events-none
  ;

  transition: all ease .2s;
  opacity: 0;

  &._loading {
      opacity: 1;
  }
}

.detail-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#bid-panel {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  & .bid-history-pane {
      border: solid 3px rgb(139, 139, 139);
      border-radius: 16px;
      padding: 1rem;
      height: 80%;
      width: 100%;
      color: black !important;

      & ._title {
          margin-bottom: 1.1rem;
          font-size: 1.4rem;
      }

      & .bid-entry {
          @apply
              custom:artwork-card rounded-lg shadow-md hover:shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden
          ;
          color: black;
          border: 1px solid #d3d2d2;
          border-radius: 8px;
          margin: 0;
          margin-bottom: 0.5rem;
          display: flex;
          justify-content: space-between;
          overflow-y: scroll;
          
          & ._pricetag {
            padding: 1rem;

            & ._address {
                
            }
        
            & ._price {
        
            }
        
            & ._symbol {
        
            }
          }

      }
  }

  & ._actions {
      display: flex;
      justify-content: space-between;

      & .button-group {
          margin-top: 0 !important;
      }
  }

  & .bid-info {
  }
  
  & ._close-panel {
      position: absolute;
      top: -10px;
      right: -10px;
      color: black;
      border-radius: 50%;
      border: 2px solid black;
      width: 40px;
      height: 40px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
  }

}

.nft-title {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  ._title {
      max-width: 80%;
      font-size: 3rem;
      line-height: 1;
      word-break: break-word;
      font-weight: 500;
      letter-spacing: 0.7px;
  }

  .sell-badge {
      background: #d40f4c;
      padding: 0.2rem 0.6rem;
      border-radius: 10px;
      color: white;
      width: max-content;
      height: auto;
      font-size: 0.7rem;
      font-weight: 800;
      margin: 0.8rem 1rem;
  }
}

.interaction-panels {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  min-height: 70vh;
  position: relative;
  transition: all ease .5s;

  & ._close-panel {
      position: absolute;
      top: -10px;
      right: -10px;
      color: black;
      border-radius: 50%;
      border: 2px solid black;
      width: 40px;
      height: 40px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  & ._setup-panel {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
  }
}



.swal-modal {
  @apply
      custom:artwork-card rounded-xl shadow-lg hover:shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden
  ;

  & .swal-button-container {

      & .swal-button {

      }

      & .swal-button--confirm {
          @apply
              w-80 flex-none bg-gray-900 hover:bg-gray-700 text-white text-lg leading-8 font-semibold py-3 px-4 border border-transparent rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200
          ;
      }
  }
}

.current-price {
  font-size: 1.5rem;
  color: black;
}

.seller-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > * {
      margin-bottom: 1rem;
  }
}

.price-input-panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 3px solid black;
  width: max-content;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 1rem;
  & input {
      font-size: 1.4rem;
      color: black;
  }
  
  & select {
      font-size: 1.3rem;
      color: black;
  }
}

.ownership-display {
  font-size: 1rem;
  color: black;
}

.slide-show {

  &._billboard {
      height: calc(100vh - 100px);
      max-height: 1080p;
      min-height: fit-content;
      background: black;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {

      }
  }

}

.heroes-display {
  min-height: calc(100vh - 100px);
}

.coverflow-x {
  background: black;
}

.selected-artists-container {
  max-width: 1440px;
  margin: auto;
}

.selected-artists-item-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 40px auto;
}

.selected-artist-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  & ._title {
      text-align: center;
      padding-top: 0.7rem;
      font-size: 1.2rem;
      line-height: 2rem;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.25px;
      font-family: 'Prompt', sans-serif;
  }

  & ._image {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      overflow: hidden;
      box-shadow: 0 8px 28px 1px #0000005c;
      margin: 20px 40px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      
      & ._border {
          width: inherit;
          height: inherit;
          position: absolute;
          top: -1;
          left: -1;
          right: -1;
          bottom: -1;
          z-index: 99999;
          border: 6px solid #ffffffb3;
          border-radius: inherit;
          background: linear-gradient(-31deg, rgba(200, 200, 200, 0.2) 0%, rgba(255, 255, 255, 0.15) 70%, rgba(255, 255, 255, 0.5) 85%, rgba(255, 255, 255, 0.1) 100%);
      }

      & img {
          width: calc(100%);
          height: calc(100%);
          border-radius: inherit;
      }
  }
}

.selected-artists-detail {
  max-width: 600px;
  width: 80%;
  height: 0;
  overflow-y: scroll;
  margin: 50px auto;
  text-align: center;
  font-family: 'Prompt', sans-serif;
  transition: all 100ms ease;
  
  &._show {
      height: 200px;
  }
}

.timer {
  border: 2px solid black;
  padding: 0.4rem;
  color: white;
  border-radius: 1rem;
  background: black;
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: none;

  &._active {
      display: block;
  }

  & .countdown-timer {
      width: 100%;
      display: inline-flex;

      & ._title {
          font-size: 1.6rem;
          width: max-content;
          margin: auto 1.2rem;
      }

      // --- CountdownTimer --- //
      & .countdown-timer-content {
          @apply inline-flex text text-gray-900;
          color: white;
          margin: auto 2px;

          & .countdown-timer-column {
              @apply flex is-flex-direction-column mr-3;

              & .value {
                  @apply font-bold text-2xl;
              }
              & .unit {
                  margin-top: -5px;
              }
          }
      }

      &.mini {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        & > ._title {
          color: #dddddd;
          font-size: 0.8rem;
          margin: 0;
        }

        & > ._clock {
          @apply inline-flex text text-gray-900;
          font-size: 1.2rem;
          color: white;
          
          & > ._column {
            margin-right: 0.5rem;
          }
        }
      }
  }
}

.button-round-default {
  @apply 
    rounded-xl inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white
  ;
  //custom:button-gradient bg-indigo-600 hover:bg-indigo-700
  // width: max-content;
  padding: 12px;
  background: none;
  color: rgba(255, 255, 255, 0.9);
  // border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: .3s all ease-in;
  position: relative;
  box-sizing: content-box;

  &._active {
    background: white;
    color: red;
    pointer-events: none;

    & ._tooltip {
      opacity: 1;
    }
  }

  &:hover {
    color: white;
    border-color: white;
    box-shadow: 0 0 4px blanchedalmond;
    background:rgba(255, 255, 255, 0.3);

    & ._icon {
      transition-duration: .1s!important;
      transform: translateY(-4px);
      transform:scale(150%);
    }

    & ._tooltip {
      opacity: 1;
      transition-duration: .1s!important;
      transform: translateX(5px);
    }
  }

  & ._icon {
    // width: max-content;
    transition: .5s all ease-in;
    transition-delay: .05s;
    border-radius: 4px;
    pointer-events: none;
    transform:scale(130%);
  }

  & ._tooltip {
    @apply
      absolute px-2 py-1 text-white text-right
    ;
    right: 50px;
    // width: max-content;
    transition: .5s all ease-in;
    transition-delay: .05s;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;

    & ._text {
      @apply
        text-title tracking-wide uppercase w-full leading-3
      ;
      // width: 100%;
      // text-transform: uppercase;
      // line-height: 0.8;
      // letter-spacing: 2px;
      // font-weight: 100;
    }
  }
}

.create-button-plus {
  @apply 
    rounded-xl inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white
  ;
  //custom:button-gradient bg-indigo-600 hover:bg-indigo-700
  padding: 12px;
  background: none;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: .3s all ease-in;
  position: relative;
  box-sizing: content-box;

  &._active {
    background: white;
    color: red;
    pointer-events: none;

    & ._tooltip {
      opacity: 1;
    }
  }

  &:hover {
    color: white;
    border-color: white;
    box-shadow: 0 0 4px blanchedalmond;
    background:rgba(255, 255, 255, 0.3);

    & ._icon {
      transition-duration: .1s!important;
      transform: translateY(-4px);
      transform:scale(150%);
    }

    & ._tooltip {
      opacity: 1;
      transition-duration: .1s!important;
      transform: translateX(5px);
    }
  }

  & ._icon {
    // width: max-content;
    transition: .5s all ease-in;
    transition-delay: .05s;
    border-radius: 4px;
    pointer-events: none;
    transform:scale(130%);
  }

  & ._tooltip {
    @apply
      absolute px-2 py-1 text-white text-right
    ;
    right: 50px;
    // width: max-content;
    transition: .5s all ease-in;
    transition-delay: .05s;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;

    & ._text {
      @apply
        text-title tracking-wide uppercase w-full leading-3
      ;
      // width: 100%;
      // text-transform: uppercase;
      // line-height: 0.8;
      // letter-spacing: 2px;
      // font-weight: 100;
    }

  }

}

.font-opensans {
  font-family: 'Open Sans', Arial, Helvetica Neue, sans-serif;
  font-size: 1rem;
}

.text-opensans {
  @apply
    font-opensans
  ;
}

.text-title {
  @apply
    font-opensans
    font-thin
  ;
}

.section-title {
  @apply
    text-title text-xl tracking-wide leading-3
  ;
}

//

.jimmyis-bg-gradient-living-vein {
  background: linear-gradient(242deg, #ff00a6, #fd7200, #1fc2d4);
  background-size: 600% 600%;

  -webkit-animation: LivingVein 30s ease infinite;
  -moz-animation: LivingVein 30s ease infinite;
  animation: LivingVein 30s ease infinite;
}

@-webkit-keyframes LivingVein {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes LivingVein {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes LivingVein {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.jimmyis-shadow-1 {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.02);
}
.jimmyis-shadow-1-2 {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.jimmyis-shadow-2 {
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15);
}
.jimmyis-shadow-2-2 {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.jimmyis-roundy {
  border-radius: 9999px;
}

.jimmyis-softpod {
  @apply
    jimmyis-shadow-1
    jimmyis-roundy
  ;
  display: inline-block;
  will-change: transform;
  width: 80%;
  height: 4rem;
  padding: 0.2rem 1rem;
  background: #ffffff;
  margin-top: -9px;
  z-index: -999;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    @apply
      jimmyis-shadow-1-2
    ;
    transform: translateY(-3px);
    
  }
}

.jimmyis-boxfx-shadowlifter {
  @apply
    jimmyis-shadow-2
  ;
  z-index: 999;
  transition: all 2s ease-in;
  
  &:hover {
    @apply
      jimmyis-shadow-2-2
    ;
  }
}

.jimmyis-softcard {
  @apply 
    relative
    jimmyis-boxfx-shadowlifter
  ;
  border-radius: 8px;
  padding-top: 2rem;

  & > ._title {
    @apply
      absolute left-0 right-0
      mx-auto
      bg-white
      text-center
      flex items-center justify-center
      jimmyis-shadow-1
      jimmyis-roundy
      ;
    top: -2rem;
    width: max-content;
    max-width: 90%;
    padding: 1.4rem 3%;
    font-family: 'Open Sans', Arial, Helvetica Neue, sans-serif;
    font-size: 1.2rem;
    // text-transform: uppercase;
    line-height: 0.8;
    letter-spacing: 3px;
    font-weight: 600;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      border-radius: inherit;
      height: 100%;
      box-shadow: 0 -5px 10px #0000000d;
      top: 0;
      left: 0; 
    }
  }
}

@keyframes blur {
  0% {
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
  }
  90% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
  }
  
}

.blur-fx {
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // margin: auto;
  // width: 100px;
  // height: 100px;
  background: skyblue;
  animation: blur .7s ease 0s normal;
  -webkit-animation: blur .7s ease 0s normal;
  -moz-animation: blur .7s ease 0s normal;
}

.work-card {
  transition: all .5s ease-out;
  width: 320px;
  background: white;
  
  &:hover {
    box-shadow: 0 10px 20px #5454546b !important;
    transform: translateY(-10px)
  }

  // &._selected {
  //   position: fixed;
  //   top: 200px;
  //   transform: scale3d(1.5, 1.5, 1.5);
  //   z-index: 99999;
  //   border: none;
  //   box-shadow: none;
  // }
}

.section {
  @apply 
    py-20
  ;
  border-top: 1px solid #efefefb0;
  position: relative;

  // background: rgba(237,237,237,1);
  // background: -moz-linear-gradient(top, rgb(240, 240, 240) 0%, rgba(246,246,246,1) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
  // background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(240,240,240,1)), color-stop(47%, rgba(246,246,246,1)), color-stop(75%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
  // background: -webkit-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(246,246,246,1) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
  // background: -o-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(246,246,246,1) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
  // background: -ms-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(246,246,246,1) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
  // background: linear-gradient(to bottom, rgba(240,240,240,1) 0%, rgba(246,246,246,1) 47%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff', GradientType=0 );

  background: rgba(255,255,255,1);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,250,255,1) 47%, rgba(242,242,242,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(25%, rgba(255,255,255,1)), color-stop(47%, rgba(255,250,255,1)), color-stop(100%, rgba(242,242,242,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,250,255,1) 47%, rgba(242,242,242,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,250,255,1) 47%, rgba(242,242,242,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,250,255,1) 47%, rgba(242,242,242,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,250,255,1) 47%, rgba(242,242,242,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );

  & ._title-container {
    @apply
      absolute mx-auto left-0 right-0
    ;
    top: -2rem;
    width: 80%;
    max-width: 1440px;

    & ._title {
      @apply
      jimmyis-roundy jimmyis-shadow-1-2 bg-white flex justify-center items-center
      mx-auto text-center
      ;
      // section-title 
      width: 60%;
      height: 4rem;
      padding: 1.5rem 4rem;
      font-weight: 200;
      font-size: 1.5rem;

      padding-top: 1.5rem;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 800;
      line-height: 1;
      letter-spacing: -0.025em;
      --tw-text-opacity: 1;
      color: rgba(17, 24, 39, var(--tw-text-opacity));

      & > span {
        @apply
          font-pacifico
        ;
        letter-spacing: 1px;
        border-color: #ec4899;
      }
    }
  }
}

.styled-text-colourful-1 {
  background: linear-gradient( 358deg , #ff7a18, #ec4899 41.07%, #00bcc7 76.05%)!important;
  -webkit-text-fill-color: transparent!important;
  -webkit-background-clip: text!important;
}

.font-pacifico {
  font-weight: 500;
  font-size: 1.5rem;
  font-family: "Pacifico", cursive;
  background: black;
  -webkit-background-clip: text;
}

#topbar {
  transition: all .2s ease-out;
  min-height: 80px;
  overflow: hidden;

  &._expand {
    height: 300px;
  }

  &._hide {
    height: 80px;
  }
}

#profile-mini {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 2rem;
  display: flex;
  opacity: 0.2;
  transition: all ease-in 1s;

  &._active {
    opacity: 1;
  }

  & ._details {
    @apply
      inline-flex justify-between flex-grow
    ;

    & ._profile-details {
      @apply
        inline-flex justify-between
      ;
  
      & ._avatar {
        @apply
          mr-8 w-36 h-36 rounded-full border border-white bg-white
        ;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
  
      & ._description {
        @apply
          flex flex-col justify-center items-start
        ;
  
        & ._name {
          @apply
            text-white font-bold text-4xl
          ;
          // font-size: 2rem;
          letter-spacing: 1px;
        }
      }
    }

    & ._buttons-panel {
      @apply
        flex flex-col items-end
      ;
      
      & > * {
        @apply
          mb-2
        ;
      }
    }
  }
}

.profile-panel-close {
  @apply 
    rounded-xl inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white
  ;
  //custom:button-gradient bg-indigo-600 hover:bg-indigo-700
  padding: 12px;
  background: none;
  color: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: .3s all ease-in;
  position: relative;
  box-sizing: content-box;

  &._hide {
    transform: translateX(50px);
    opacity: 0;
    pointer-events: none;
  }

  &._active {
    display: block;
    background: white;
    color: grey;
    opacity: 1;
    transform: scale(1.5);

    & ._tooltip {
      opacity: 1;
    }
  }

  &:hover {
    color: red;
    border-color: white;
    box-shadow: 0 0 4px blanchedalmond;
    background:rgba(255, 255, 255, 0.3);

    & ._icon {
      transition-duration: .1s!important;
      transform: translateY(-4px);
      transform:scale(150%);
    }

    & ._tooltip {
      opacity: 1;
      transition-duration: .1s!important;
      transform: translateX(5px);
    }
  }

  & ._icon {
    // width: max-content;
    transition: .5s all ease-in;
    transition-delay: .05s;
    border-radius: 4px;
    pointer-events: none;
    transform:scale(130%);
  }

  & ._tooltip {
    @apply
      absolute px-2 py-1 text-white text-right
    ;
    right: 50px;
    // width: max-content;
    transition: .5s all ease-in;
    transition-delay: .05s;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;

    & ._text {
      @apply
        text-title tracking-wide uppercase w-full leading-3
      ;
      // width: 100%;
      // text-transform: uppercase;
      // line-height: 0.8;
      // letter-spacing: 2px;
      // font-weight: 100;
    }

  }
}

.avatar-display {
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  
  & ._placeholder {
    width: 100%;
    height: 100%;
    background-size: auto;
    transition: all .5s ease;
  }
}

#btn-faucet {
  @apply
    button-round-default
  ;
}

.fullvideo {
  @apply
    mx-auto
    jimmyis-softcard
  ;
  width: calc(100% - 2rem);
  max-width: 1920px;
  height: auto;
  margin-top: 4rem;
  padding: 1rem;
  
  & iframe {
    width: 100%;
    height: 80vh;
    max-height: 100vh;
  }
}

.button-small {
  // width: max-content;
  padding: 0.6rem 0.8rem;
  background: black;
  text-transform: uppercase;
  border-radius: 2rem;
  font-size: 0.8rem;
  color: white;
  height: 90%;
  margin: auto;
  margin-right: .6rem;
}

.blog-explorer {
  @apply
    mx-auto
    jimmyis-softcard
  ;
  width: 80vw;
  max-width: 1920px;
  min-height: 300px;
  height: auto;
  margin-top: 4rem;
  padding: 1rem;
  
}

.blog-post-entries {
  width: 80%;
  margin: auto;
  padding-top: 4rem;
}

.blog-post-entry {
  min-height: 200px;
  margin: 2rem;
  box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.2)l;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & ._thumb-image {
    @apply
      jimmyis-softcard
    ;
    width: 450px;
    height: 300px;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
  }

  & ._info {
    padding: 1rem 2rem;

    & ._title {
      font-size: 1.6rem;
    }
  }
}

.blog-post {
  @apply
    mx-auto
    jimmyis-softcard
  ;
  width: 80vw;
  max-width: 1920px;
  min-height: 300px;
  height: auto;
  margin-top: 4rem;
  padding: 1rem;

  & > p {
    padding: 2rem;
    & > * {
      display: block;
    }

    & span {
      margin: 2rem 0;
      padding: 2rem 1.6rem;
      font-size: 1.2rem;
    }
  }
}

.view-more-small {
  background: black;
  border-radius: 8px;
  color: white;
  font-size: 0.8rem;
  margin-left: 8px;
  text-transform: lowercase;
  padding: 2px 8px;
  transition: all .5s ease;

  &:hover {
    background: red;
  }
}

.item-card {
  @apply
    custom:artwork-card rounded-lg shadow-md hover:shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden
  ;
  background: white;
  transition: all .3s ease-in;

  & a {
      @apply block;
      
      & > .cover-art {
          figure {
              @apply custom:cover-art-sqauare;
          }
      }
    
      & > .title-art {
          h3 {
              @apply text-xl sm:text-xl font-extrabold text-gray-900 px-5 py-5 space-y-6 sm:space-y-0 sm:space-x-10 sm:px-8 pb-0;
          }
    
          .price-art {
              @apply block text-base px-5 py-5 space-y-6 sm:space-y-0 sm:space-x-10 sm:px-8;
    
              span {
                  @apply block text-xs font-light uppercase text-gray-300;
              }
          }

          & ._pricetag {
            background: rgb(238, 238, 238);
            color: black;
            min-height: 100px;
            display: flex;

            &._active {
              background: rgb(66, 66, 66);
              color: white;
            }

            & > div {
              margin: 1rem;
            }

            & ._price {

              & > ._title {
                color: #dddddd;
                font-size: 0.8rem;
              }
            }
          }
      }
  }


  &._loading {
    // background: red;
    filter: blur(4px);
  }
}
